<template>
  <div>
    <section class="hero">
      <div class="container">
        <div class="__content">
          <div class="hero-title">Наша методика</div>
        </div>
      </div>
    </section>
    <section class="method-section s-method--text">
      <div class="container">
        <div class="__text">
          <div>
            <h1 class="h1-text">Тест Эмоционального Интеллекта (ТЭИ), применяемый на платформе
              WAY2WEI для диагностики уровня развития ЭИ — это русскоязычная
              задачная методика</h1>, разработанная на основе теоретической модели эмоционального
            интеллекта как способности
            <Popper hover offsetDistance="8">
              <template #content>
                <div class="tooltip-content">
                  <span class="name">Джон Д. Мэйер, PH.D.</span>
                  Профессор психологии в Университете штата Нью-Гэмпшир. Изучает
                  психологию личности в области EI.
                </div>
              </template>
              <span class="tooltip">
                <img src="@/assets/images/methodology/meier.webp" alt="" />
                <span>Дж. Мэйера</span>
              </span></Popper
            >,
            <Popper hover offsetDistance="8">
              <template #content>
                <div class="tooltip-content">
                  <span class="name">Питер Сэловей, PH.D.</span>
                  Профессор психологии, Президент Йельского университета. Автор
                  книг по изучению EI, переведенных на одиннадцать языков.
                </div>
              </template>
              <span class="tooltip">
                <img src="@/assets/images/methodology/solovei.webp" alt="" />
                <span>П. Сэловея</span>
              </span></Popper
            >
            и
            <Popper hover offsetDistance="8">
              <template #content>
                <div class="tooltip-content">
                  <span class="name">Дэвид Карузо, PH.D.</span>
                  Исследователь центра развития EI Йельского университета,
                  ментор по развитию EI в Google, Wharton, Bristol Myers Squibb,
                  World Economic Forum и др.
                </div>
              </template>
              <span class="tooltip">
                <img src="@/assets/images/methodology/caruso.jpg" alt="" />
                <span>Д. Карузо</span>
              </span></Popper
            >
            а также на базе психоэволюционной теории эмоций Р. Плутчика
            под руководством д. психол. н., проф. ИП РАН Е.А. Сергиенко
          </div>
          <p>
            Тест Эмоционального Интеллекта разрабатывался по аналогии с тестом
            эмоционального интеллекта Мэйера-Сэловея-Карузо
            (MSCEIT–Mayer-Salovey-Caruso Emotional Intelligence Test), но с
            рядом обоснованных изменений для российской выборки. С 2006 года
            наша научная группа под руководством
            <Popper hover offsetDistance="8">
              <template #content>
                <div class="tooltip-content">
                  <span class="name">Елена Алексеевна Сергиенко</span>
                  Доктор психологических наук. Профессор, главный научный
                  сотрудник Института психологии РАН, член Президиума
                  Российского психологического общества.
                </div>
              </template>
              <span class="tooltip">
                <img src="@/assets/images/methodology/sergienko.jpg" alt="" />
                <span>Елены Алексеевны Сергиенко</span>
              </span></Popper
            >
            зарегистрировала 11 патентов.
          </p>
        </div>
      </div>
    </section>

    <section class="method-section s-method--text">
      <div class="container">
        <div class="s-title">Практическая польза</div>
        <div class="__text">
          <p>
            Наша методика позволяет точно определять уровень эмоционального
            интеллекта тестируемого, исключая возможность социально приемлемого
            ответа. Международные исследования показали влияние уровня ЭИ на
            личное и профессиональное развитие:
          </p>
          <ul>
            <li>
              Чем выше EI руководителя, тем более эффективно он справляется со
              стрессовыми ситуациями и достигает поставленных целей (r=,391).
            </li>
            <li>
              Развитие EI руководителей является значимым фактором профилактики
              эмоционального выгорания, повышения стрессоустойчивости и
              эффективности руководителей (r=,346).
            </li>
            <li>
              Руководители с высоким EI меньше подвержены «финансовому» стрессу
              (r=,217).
            </li>
            <li>
              Уровень ЭИ руководителей значимо взаимосвязан с принятием
              управленческих решений, повышающих эффективность организации,
              увеличивающих прибыль и рентабельность вложенных в ее развитие
              инвестиций.
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="method-section s-method--history slider-lg">
      <div class="container">
        <slider-lg title="История" name="history">
          <swiper-slide v-for="data in diagnosticData" :key="data.id">
            <img :src="data.img" alt="" />
            <div class="__title">{{ data.year }}</div>
            <div class="__text">
              <p>{{ data.text }}</p>
            </div>
          </swiper-slide>
        </slider-lg>
      </div>
    </section>

    <section class="method-section s-method--text">
      <div class="container">
        <div class="s-title">Онлайн-тестирование</div>
        <div class="__text">
          <p>
            Результаты Теста Эмоционального Интеллекта позволяют оценить
            актуальные возможности человека, что особенно важно при подборе
            персонала, а также выявить следующие шаги и перспективы развития.
            Особенно облегчает жизнь возможность пройти тест эмоционального
            интеллекта онлайн как для проводящего, так и для респондента.
          </p>
          <p>
            Онлайн-тестирование ЭИ открывает большие возможности: увеличивает
            мобильность и доступность, ведь для того, чтобы пройти
            онлайн-тестирование ТЭИ, можно воспользоваться даже смартфоном. Если
            специалист работает с большим количеством людей, то для него будет
            удобно использовать тесты на эмоциональный интеллект онлайн, в
            которых сразу дается расшифровка результатов.
          </p>
          <p>
            Таким образом, русскоязычная методика ТЭИ является одной из лучших
            среди предлагаемых на рынке за счет безусловной объективности
            оценок, практичности и скорости в применении, созданной под
            стандарты русскоязычного бизнеса.
          </p>
        </div>
      </div>
    </section>

    <section class="method-section s-method--text">
      <div class="container">
        <img
          class="method-graphic"
          src="@/assets/images/EIIndicator.jpg"
          alt=""
        />
        <div class="s-title">
          Показатели надежности Теста Эмоционального Интеллекта
        </div>
        <div class="__text">
          <p>
            По результатам психометрической проверки ТЭИ показал высокую степень
            надежности (Alpha Cronbach общего уровня EI = 0,93), структурной и
            факторной валидности (χ2 = 39,87, RMSEA =0,027 (0.001; 0.044), CFI =
            0,99)
          </p>
        </div>
      </div>
    </section>

    <section class="method-section s-team--materials">
      <div class="container">
        <div class="s-material--grid">
          <div class="__item">
            <div class="__title">Патенты</div>
            <div class="__text">
              <p>
                Право ООО «Лаборатории эмоционального интеллекта» на
                интеллектуальную собственность по тематике проекта подтверждено:
              </p>
              <p>
                <a
                  href="https://www1.fips.ru/registers-doc-view/fips_servlet?DB=EVM&DocNumber=2018618517&TypeFile=html"
                  >1. Программа для ЭВМ «Тест Эмоционального Интеллекта» -
                  Регистрационный номер в ФИПС: 2018618517.</a
                >
              </p>
              <p>
                <a
                  href="https://www1.fips.ru/registers-doc-view/fips_servlet?DB=DB&DocNumber=2019620883&TypeFile=html"
                  >2. База данных «Тест Эмоционального Интеллекта» -
                  Регистрационный номер в ФИПС: 2019620883.</a
                >
              </p>
              <p>
                <a
                  href="https://www1.fips.ru/registers-doc-view/fips_servlet?DB=RUPAT&DocNumber=2720400&TypeFile=html"
                  >3. Способ повышения эффективности человека на основе оценки и
                  развития эмоционального интеллекта - Регистрационный номер в
                  ФИПС: 2019145107.</a
                >
              </p>
              <p>
                4. Товарный знак «Лаборатория ЭИ» - Регистрационный номер в
                ФИПС: 2018706165.
              </p>
            </div>
            <div v-if="showAll" class="__text">
              <p>
                5. Товарный знак, словесный знак обслуживания «ТЭИ Тест
                Эмоционального Интеллекта» - Регистрационный номер в ФИПС:
                744133
              </p>
              <p>
                6. Товарный знак, словесный знак обслуживания «РЭИ» -
                Регистрационный номер в ФИПС: 756457
              </p>
              <p>
                7. Товарный знак, знак обслуживания «Работающий Эмоциональный
                Интеллект» - Регистрационный номер в ФИПС: 644415
              </p>
              <p>
                8. Товарный знак, знак обслуживания «ЛАБОРАТОРИЯ ЭМОЦИОНАЛЬНОГО
                ИНТЕЛЛЕКТА» - Регистрационный номер в ФИПС: 630782
              </p>
              <p>
                9. Товарный знак, знак обслуживания «WorkingEI» -
                Регистрационный номер в ФИПС: 741671
              </p>
              <p>
                10. Товарный знак, словесный знак обслуживания «WEI Working
                Emotional Intelligence» - Регистрационный номер в ФИПС: 740852
              </p>
              <p>
                11. Товарный знак, словесный знак обслуживания «EIT Emotional
                intelligence test» - Регистрационный номер в ФИПС: 743834
              </p>
            </div>
            <div class="btn-action display--flex">
              <button
                v-if="!showAll"
                @click="showAll = true"
                type="button"
                class="btn btn-o btn-sm"
              >
                Показать все
              </button>
              <button
                v-if="showAll"
                @click="showAll = false"
                type="button"
                class="btn btn-o btn-sm"
              >
                Скрыть
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { SwiperSlide } from "swiper/vue";
import SliderLg from "../../components/sliders/slider-lg/SliderLg.vue";
import Popper from "vue3-popper";
import { useHead } from '@vueuse/head'
import { ref } from "@vue/reactivity";

export default {
  components: { SliderLg, SwiperSlide, Popper },
  name: "methodology-page",
  setup() {
    useHead({
      title:
        "Тест Эмоционального Интеллекта (ТЭИ) на платформе WAY2WEI для диагностики уровня развития ЭИ",
      meta: [
        {
          property: "og:description",
          content:
            "Результаты Теста Эмоционального Интеллекта позволяют оценить актуальные возможности человека, что особенно важно при подборе персонала, а также выявить следующие шаги и перспективы развития.",
        },
        {
          property: "og:keywords",
          content:
            "тест эмоционального интеллекта, msceit, msceit тест, ei тест, оценка эмоционального интеллекта, диагностика эмоционального интеллекта, методика диагностики эмоционального интеллекта, диагностика и развитие эмоционального интеллекта, тест диагностика эмоционального интеллекта, Карузо",
        },
      ],
    });
    let diagnosticData = [
      {
        id: 1,
        text: "Профессора Йельского университета и университета Нью-Гэмпшир – П. Сэловей (в настоящее время Президент Йельского университета) и Дж. Мэйер ввели в научную терминологию понятие «эмоциональный интеллект» (emotional intelligence, EI), выпустив статью с одноименным названием.",
        year: "1990",
        img: require('@/assets/images/methodology/history/slide1.webp')
      },
      {
        id: 2,
        text: "Д. Гоулман опубликовал книгу «Emotional Intelligence, EQ», однако вместе с популяризацией EQ, несколько переиначил научную базу основателей. Д. Гоулман в значительной степени приравнивал эмоциональный интеллект к «правильному» социальному поведению.",
        year: "1995",
        img: require('@/assets/images/methodology/history/slide2.webp')
      },
      {
        id: 3,
        text: "Дж. Мэйер и П. Сэловей доработали и расширили модель эмоционального интеллекта – модель способностей и создали первый тест на определение EI в рамках модели способностей (MEIS).",
        year: "1997",
        img: require('@/assets/images/methodology/history/slide3.webp')
      },
      {
        id: 4,
        text: "К Дж. Мэйеру и П. Сэловею присоединился Д. Карузо. Разработана и признана международным научным сообществом объективная методика определения уровня эмоционального интеллекта – тест  Карузо Д., Мэйера Дж. и Сэловея П. – MSCEIT (Mayer-Salovey-Caruso Emotional Intelligence Test).",
        year: "1999",
        img: require('@/assets/images/methodology/history/slide4.webp')
      },
      {
        id: 5,
        text: "Под руководством д.пс.н. Е.А. Сергиенко, профессора ИП РАН, при участии экспертов Международного центра КТК проведена адаптация российской версии теста MSCEIT в целях научных исследований. Работа над адаптацией теста к российской выборке велась с 2006 года.",
        year: "2010",
        img: require('@/assets/images/methodology/history/slide5.webp')
      },
      {
        id: 6,
        text: "Выпущена первая сертифицированная программа развития ЭИ и подготовки тьюторов развития ЭИ, разработанная на основе научной модели способностей ЭИ при научной экспертизе и личном участии исследователя Центра развития эмоционального интеллекта Йельского университета Д. Карузо   при поддержке Европейской ассоциации культурных и эмоционально-интеллектуальных проектов (European Association of Cultural and Emotionally Intelligent Projects (EACEIP).",
        year: "2014",
        img: require('@/assets/images/methodology/history/slide6.webp')
      },
      {
        id: 7,
        text: "Экспертами МЦ КТК и исследователями ИП РАН под руководством д.пс.н., Е.А. Сергиенко начата работа над созданием русскоязычной методики диагностики ЭИ (ТЭИ, тест эмоционального интеллекта) на научной модели ЭИ как способности с целью массового использования в консультативной и развивающей практике.",
        year: "2015",
        img: require('@/assets/images/methodology/history/slide7.webp')
      },
      {
        id: 8,
        text: "Методика ТЭИ прошла стадии психометрической проверки и валидизации и выпущена для использования в онлайн-формате.",
        year: "2018",
        img: require('@/assets/images/methodology/history/slide8.webp')
      },
      {
        id: 9,
        text: "Выпущен первый онлайн-тренажер развития эмоционального интеллекта по индивидуальной траектории на основе диагностики по методике ТЭИ.",
        year: "2021",
        img: require('@/assets/images/methodology/history/slide9.webp')
      },
    ];

    let showAll = ref(false);

    return {
      diagnosticData,
      showAll,
    };
  },
};
</script>

<style scoped>
.team-section,
.method-section {
  padding: 50px 0;
}

.team-section + .team-section,
.method-section + .method-section {
  padding-top: 0;
}

.method-graphic {
  max-width: 450px;
}

.s-method--text .__text {
  font-size: 28px;
  line-height: 39px;
  text-align: left;
}

.s-method--text .__text {
  margin: 0 0 20px 0;
}

.s-method--text .__text .tooltip {
  position: relative;
  display: inline;
  padding-left: 44px;
  color: var(--accent-l);
}

.s-method--text .__text .tooltip span {
  border-bottom: 1px dashed var(--accent-l);
}

.s-method--text .__text .tooltip img {
  position: absolute;
  display: inline-flex;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
}

.s-method--text .__text .tooltip:hover {
  color: var(--accent);
}

.s-method--text .__text .tooltip:hover span {
  border-bottom: 1px solid var(--accent);
  cursor: default;
}

.s-method--text .tooltip-content {
  max-width: 350px;
  background-color: var(--accent);
  color: white;
  border-radius: 10px;
  font-size: 18px;
  line-height: 120%;
  padding: 20px;
}

.s-method--text .tooltip-content span {
  display: block;
  font-size: 24px;
  line-height: 28px;
  padding-bottom: 12px;
}

.s-method--text ul {
  padding: 0;
  font-size: 24px;
  line-height: 29px;
  list-style: none;
}

.s-method--text ul > li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 30px;
}

.s-method--text ul > li::before {
  position: absolute;
  display: block;
  content: "";
  top: 14px;
  left: 0;
  width: 20px;
  height: 1px;
  background: var(--accent);
}

.s-team--materials .__title {
  margin-bottom: 5px;
  font-size: 28px;
  line-height: 34px;
}

.s-material--grid a{
  color: var(--color);
}

.s-material--grid a:hover{
  text-decoration: underline;
}

.h1-text{
  font-weight: 300;
  display: inline;
  font-size: 28px;
  line-height: 39px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .team-section,
  .method-section {
    padding: 35px 0;
  }

  .s-method--text .__text,
  .s-method--text ul > li {
    font-size: 18px;
    line-height: 22px;
  }

  .s-method--text .__text .tooltip {
    padding-left: 35px;
  }

  .s-method--text .__text .tooltip img {
    width: 25px;
    height: 25px;
  }

  .s-method--text .s-title {
    font-size: 28px;
    line-height: 34px;
  }

  .method-graphic{
    max-width: 100%;
  }
}
</style>
