<template>
  <div class="slider-lg">
    <div v-if="title" class="s-title">
      {{ title }}
    </div>
    <div class="__slider">
      <swiper
        slidesPerView="auto"
        :spaceBetween="30"
        :loop="false"
        :navigation="{
          nextEl: `.${name}-next`,
          prevEl: `.${name}-prev`,
        }"
      >
        <slot>
          <swiper-slide>1</swiper-slide>
        </slot>
        <div :class="`swiper-button-prev ${name}-prev`"></div>
        <div :class="`swiper-button-next ${name}-next`"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import Sw from "swiper";

Sw.use([Navigation]);

import "swiper/swiper-bundle.min.css";

export default {
  name: "slider-lg",

  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    title: String,
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.slider-lg .swiper-slide {
  width: 730px !important;
}

.slider-lg .swiper-container {
  overflow: visible !important;
}

.slider-lg .swiper-slide img {
  display: block !important;
  width: 100% !important;
  max-width: 730px !important;
  height: 490px !important;
  border-radius: 10px !important;
  object-fit: cover;
  user-select: none;
}

.slider-lg .swiper-button-next,
.slider-lg .swiper-button-prev {
  top: 453px !important;
  right: 0 !important;
  bottom: auto !important;
  left: auto !important;
  justify-content: center !important;
  width: 75px !important;
  height: 75px !important;
  background: #ffffff !important;
  border-radius: 50% !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2) !important;
  opacity: 1 !important;
}

.slider-lg .swiper-button-prev {
  right: 88px !important;
}

.slider-lg .swiper-button-next::after,
.slider-lg .swiper-button-prev::after {
  font-size: 28px !important;
}

.slider-lg .swiper-button-next:hover,
.slider-lg .swiper-button-prev:hover {
  background-color: var(--accent) !important;
}

.slider-lg .swiper-button-next:hover::after,
.slider-lg .swiper-button-prev:hover::after {
  color: #ffffff !important;
}

.slider-lg .swiper-button-disabled {
  opacity: 0.1 !important;
}

.slider-lg .__title {
  margin: 12px 0 0 0;
  font-size: 28px;
  line-height: 34px;
  text-align: left;
}

.slider-lg .__text {
  margin: 12px 0 0 0;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

@media (max-width: 767px) {
  .slider-lg .s-title {
    font-size: 28px;
    line-height: 34px;
  }

  .slider-lg .swiper-container {
    padding-bottom: 20px !important;
  }

  .slider-lg .swiper-slide {
    width: 230px !important;
  }

  .slider-lg .swiper-slide img {
    height: 150px !important;
  }

  .slider-lg .swiper-button-next,
  .slider-lg .swiper-button-prev {
    display: none !important;
  }
}
</style>